import React, { useState, useEffect } from "react";
import moment from "moment";
import "date-fns";
import { Button } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import MenuItem from "@material-ui/core/MenuItem";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from '@mui/material/DialogTitle';

import HttpRequest from "../HttpService.js";
import CustomDialog from "../components/dialog/Dialog.js";
import NoticeEmitter from "../NoticeUtil.js";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import Tooltip from '@material-ui/core/Tooltip';

import { Snackbar, Alert } from "@mui/material";

export default function SideButtonDialog(props) {
  const [overrideOpenDialog, setOverrideOpenDialog] = React.useState(false);
  const [overrideDialogMessage, setOverrideDialogMessage] = React.useState("");
  const [selectJobList, setSelectJobList] = React.useState("");
  const [inputs, setInputs] = React.useState({});
  const [funcParamsList, setFuncParamsList] = React.useState([]);

  const [openSnack, setOpenSnack] = React.useState(false);
  const [messageInfo, setMessageInfo] = React.useState("");

  const defaultDate = new Date();

  useEffect(() => {
    try {
      if (props.button) {
        console.log(
          "SideButtonDialog button procedureName -" + JSON.stringify(props.button.procedureName)
        );
        console.log("button params-" + JSON.stringify(props.button.paramaters));
        const row = props.button;
        let functionParamsList = [];
        if (row.paramaters !== null && row.paramaters.length !== 0) {
          row.paramaters = row.paramaters.split("[").join("{");
          row.paramaters = row.paramaters.split("]").join("}");
          row.paramaters = row.paramaters.replace('"values":{', '"values":[');
          row.paramaters = row.paramaters.replace('},"default"', '],"default"');
          var obj = JSON.parse(row.paramaters);
          for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
              console.log(key + " = " + JSON.stringify(obj[key]));
              var funcObj = obj[key];
              if (funcObj.type === "dropdown") {
                //console.log(funcObj.values);
                functionParamsList.push({
                  label: key,
                  prompt: funcObj.prompt,
                  type: funcObj.type,
                  default: funcObj.default,
                  length: funcObj.length,
                  mo: funcObj.mo,
                  list: funcObj.values,
                  value: "",
                  id: functionParamsList.length,
                });
                setInputs((prevState) => {
                  return {
                    ...prevState,
                    [key]: funcObj.default,
                  };
                });

              } else if (funcObj.type === "datetime") {
                functionParamsList.push({
                  label: key,
                  prompt: funcObj.prompt,
                  type: funcObj.type,
                  length: funcObj.length,
                  mo: funcObj.mo,
                  date_val: currentDateTime(),
                  time_val: currentDateTime(),
                  value: currentDateTime(),
                  id: functionParamsList.length,
                });
              } else if (funcObj.type === "decimal") {
                functionParamsList.push({
                  label: key,
                  prompt: funcObj.prompt,
                  type: funcObj.type,
                  length: funcObj.length,
                  mo: funcObj.mo,
                  value: "",
                  precision: funcObj.precision,
                  id: functionParamsList.length,
                });
              } else {
                functionParamsList.push({
                  label: key,
                  prompt: funcObj.prompt,
                  type: funcObj.type,
                  length: funcObj.length,
                  mo: funcObj.mo,
                  value: "",
                  id: functionParamsList.length,
                });
              }
            }
          }
        }
        setFuncParamsList(functionParamsList);
      }
    } catch (e) {
      console.log("sideButtonDialog exception:" + e);
    }
  }, [props.button]);

  if (funcParamsList.length == 0) {
    return null;
  }

  function currentDateTime() {
    var current = new Date();
    return new Date(
      current.getFullYear(),
      current.getMonth(),
      current.getDate(),
      current.getHours(),
      current.getMinutes()
    );
  }

 
  const renderElements = (item, index) => {
    const params = item;
    //console.log(index + "--------------SideButtonDialog renderElements --------" + JSON.stringify(params));
    if (params.type === "varchar") {
      return (
        <Tooltip title={params.mo} placement="top-end" arrow>
        <TextField
          autoFocus={index === 0 ? true : false}
          variant="outlined"
          fullWidth
          key={params.label}
          id={params.label}
          margin="dense"
          label={params.prompt}
          defaultValue={params.default}
          onChange={(input) => {
            setInputs((prevState) => {
              return {
                ...prevState,
                [params.label]: input.target.value,
              };
            });
            /*funcParamsList.forEach((row) => {
            if(row.label === params.label){
              row.value = input.target.value;  
            }
          });*/
          }}
        />
        </Tooltip>
      );
    } else if (params.type === "integer") {
      return (
        <Tooltip title={params.mo} placement="top-end" arrow>
        <TextField
          autoFocus={index === 0 ? true : false}
          variant="outlined"
          fullWidth
          margin="dense"
          key={params.label}
          label={params.prompt}
          defaultValue={params.default}
          onChange={(input) => {
            setInputs((prevState) => {
              return {
                ...prevState,
                [params.label]: input.target.value,
              };
            });           
          }}
        />
        </Tooltip>
      );
    } else if (params.type === "dropdown") {
      return (
        <TextField
          variant="outlined"
          fullWidth
          key={params.label}
          margin="dense"
          label={params.prompt}
          select
          defaultValue={params.default}
          onChange={(input) => {
            console.log("dropdown changing name is " + params.label + "  ---value is " + input.target.value);
            setInputs((prevState) => {
              return {
                ...prevState,
                [params.label]: input.target.value,
              };
            });            
          }}        
          >
          {params.list.map((value) => (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          ))}
        </TextField>
      );
    } else if (params.type === "datetime") {
      return (
        <Tooltip title={params.mo} placement="top-end" arrow>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            renderInput={(props) => (
              <TextField autoFocus={index === 0 ? true : false} {...props} />
            )}
            label={params.prompt}
            ampm={false}
            onChange={(input) => {
              setInputs((prevState) => {
                return {
                  ...prevState,
                  [params.label]: input,
                };
              });              
            }}
          />
        </LocalizationProvider>
        </Tooltip>
      );
    } else if (params.type === "decimal") {
      return (
        <Tooltip title={params.mo} placement="top-end" arrow>
        <TextField
          autoFocus={index === 0 ? true : false}
          variant="outlined"
          fullWidth
          key={params.label}
          margin="dense"
          label={params.prompt}
          defaultValue={params.default}
          onChange={(input) => {
            setInputs((prevState) => {
              return {
                ...prevState,
                [params.label]: input.target.value,
              };
            });           
          }}
        ></TextField>
        </Tooltip>
      );
    } else if (params.type === "checkbox") {
      return (
        <Tooltip title={params.mo} placement="top-end" arrow>
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                label={params.prompt}
                defaultChecked={params.default}
                color="primary"
                onChange={(event) => {
                  setInputs((prevState) => {
                    return {
                      ...prevState,
                      [params.label]: event.target.checked,
                    };
                  });                
                }}
              />              
            }
            label={
              <Typography style={{ color: "black" }}>
                {params.prompt}
              </Typography>
            }
          />
        </FormControl>
        </Tooltip>
      );
    }
  };

  const overrideFunction = () => {
    let paramsList = funcParamsList;
    paramsList.push({
      label: "override",
      prompt: "",
      type: "bit",
      length: 1,
      mo: "override",
      value: 1,
    });
    HttpRequest.doTripListFunction(
      props.accessToken,
      selectJobList,
      paramsList,
      props.button.procedureName
    ).then(
      async (body) => {
        //alert( props.button.procedureName + " procedure is done!");
        setMessageInfo(props.button.procedureName + " procedure is done for job/jobs " + selectJobList);       
        setOpenSnack(true);
        NoticeEmitter.emit("resetTable", "");
        setOverrideOpenDialog(false);
      },
      function errorCallback(err) {
        alert("Failed to finish procedure. Error is : " + err);
      }
    );
  };


  return (
    <div>
      <Dialog
        fullWidth
        className="darkDialog"
        open={props.open}
        onClose={() => props.handleDialogClose()}
      >
        <DialogTitle>{props.button.functionText}</DialogTitle>
        <DialogContent>
          {funcParamsList.map((item, index) => renderElements(item, index))}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              //console.log(JSON.stringify(props.button))
              console.log("inputs------" + JSON.stringify(inputs));
              console.log("functionParamsList ----" + JSON.stringify(funcParamsList));
              let functionParamsList = funcParamsList;
              functionParamsList.forEach((item) => {
                if (inputs.hasOwnProperty(item.label)) {
                  item.value = inputs[item.label];
                }
              });
              setFuncParamsList(functionParamsList);

              var jobIDList = "";
              props.selection.forEach((item) => {
                jobIDList += item.jobID + ",";
              });
              setSelectJobList(jobIDList);
              console.log(
                jobIDList + "functionParamsList" + JSON.stringify(functionParamsList)
              );
              HttpRequest.doTripListFunction(
                props.accessToken,
                jobIDList,
                functionParamsList,
                props.button.procedureName
              ).then(
                function successCallback(body) {
                  console.log("procedure is done- " + JSON.stringify(body.data));                  
                  try {
                    if (body.data !== "") {
                      var res = body.data;
                      if (res[0]) {
                        console.log(res[0].ResultCode + "---" + res[0].Result);
                        if (res[0].ResultCode === 10) {
                          setOverrideDialogMessage(res[0].Result);
                          setOverrideOpenDialog(true);
                        }
                        if (res[0].ResultCode === 1 && props.button.procedureName ==="in5.trip_updateListDCT") {
                          setMessageInfo(props.button.procedureName + " Error - Invalid DCT!");
                          setOpenSnack(true);
                        }
                      }
                    } else {
                      //setMessageInfo("procedure is done!");
                      setMessageInfo("Procedure is done for job/jobs  " +  jobIDList);
                      setOpenSnack(true);
                      NoticeEmitter.emit("resetTable", "");
                    }
                  } catch (e) {
                    console.log("doTripListFunction exception:" + e);
                  }
                },
                function errorCallback(err) {
                  alert("Failed to : " + err);
                }
              );
              props.handleDialogClose();
            }}
          >
            Confirm
          </Button>
          <Button color="secondary" onClick={() => props.handleDialogClose()}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <CustomDialog
        open={overrideOpenDialog}
        onClose={() => {
          setOverrideOpenDialog(false);
        }}
        hasCloseBtn={true}
        dialogMessage={overrideDialogMessage}
        dialogTitle="Warning"
        dialogAction={() => {
          overrideFunction();
        }}
      />

      <Snackbar
        open={openSnack}
        autoHideDuration={10000}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        onClose={() => {
          setOpenSnack(false);
        }}
      >
        <Alert
          onClose={() => {
            setOpenSnack(false);
          }}
          severity="info"
          sx={{ width: "100%" }}
        >
          {messageInfo}
        </Alert>
      </Snackbar>
    </div>
  );
}
