import React, { useState, useEffect } from 'react';
import Copyright from '../Copyright';
import 'date-fns';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import HttpRequest from '../HttpService';
import {makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ReplyIcon from '@material-ui/icons/Reply';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/styles';
import Link from '@material-ui/core/Link';
import Moment from 'moment';
import objSort from '../components/helperFunctions'
import { Dialog, DialogTitle, DialogContent,DialogContentText,DialogActions} from '@material-ui/core';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import './messageHistory.css';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import DataGrid, {Column,Selection, FilterRow, Paging,Export,ColumnChooser,Pager,SearchPanel} from 'devextreme-react/data-grid';

const useStyles = makeStyles(theme => ({
    itemBox: {
        display: 'flex',
        flexDirection: 'column',       
    },
    boxNumber: {
        fontSize: 18,
        wordWrap: 'break-word'
    },
    boxCaption: {
        fontSize: 10
    },
    leftNotices:{
        fontSize: 14
    },
    searchBar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    invisible:{
        display: 'none'
    }
  }));
  
  const NavLink = withStyles({
    root: {
        color: (props) => props.selected ? '#DE3562' : '#13ACB1',
        textDecoration: 'none',
        '&:hover': {
            color: '#56D9DD',
            textDecoration: 'none',
        }
    }
  })(Link);

export default function MessageHistory(props){
    const accessToken = localStorage.getItem('access_token');

    const start = new Date();
    start.setHours(0, 0, 0, 0);
   // const companyID = 1;
    const classes = useStyles();
    const [tripStats, setTripStats] = React.useState({});
    const [messages, setMessages] = React.useState([]);
    const [startDate, setStartDate] = React.useState(start);
    const [searchText, setSearchText] = React.useState("");
    const [searchResults, setSearchResults] = React.useState([]);
    const [resultsVisible, setResultsVisible] = React.useState(false);
    const [numDrivers, setNumDrivers] = React.useState(0);
    const [numTrips, setNumTrips] = React.useState(0);
    const [msgBoxOpen, setMsgBoxOpen] = React.useState(false);
    const [msgToDriver, setMsgToDiver] = React.useState("");
    const [selectedDriver, setSelectedDriver] = React.useState("");

    const handleDateChange = newDate => {
        setStartDate(newDate);    }

    const handleSearchChange = event => {
        setSearchText(event.target.value);
    }    

    const handleSearch = (start, search) => {
        const allMessages = [];
        var id = 0;
        HttpRequest.getMessagesAll(accessToken, props.companyID, start, 10000, search).then(async function(body){   
            await body.data.forEach((message) => {
                message.datestampUTC = Moment(message.datestampUTC).format('YY-MM-DD HH:mm');
                const newMessage = {
                    ...message,
                    id: id,
                };
                id++;               
                allMessages.push(newMessage);
            }); 
            // objSort(allMessages,  ['datestampUTC', true]);
            setSearchResults(allMessages);
        });
        setResultsVisible(true);
    }

    useEffect(() => {  

        let token =  localStorage.getItem('access_token');
        if(token === undefined || token === null)
           window.location.href='/';             

    },[]);

    const renderJobID = id => {
        return(
        <NavLink href = {'/jobDetail/'+ id.value}>{id.value}</NavLink>
        )
    }

    const renderTimestamp = timeStamep => {
        return(
        <div className='timestamp'> {timeStamep.value}</div>
        )
    }

    const renderDrNum = num => {
        return(
            <div className='linkDriver'>
            <NavLink href = {'/driver/'+ num.value}>{num.value}</NavLink>
            {num.value && <IconButton className='replyButton' size='small' color='primary'
            onClick = {(e) => {
                setSelectedDriver(num.value);
                setMsgBoxOpen(true); 
            }}><ReplyIcon/>
            </IconButton>}
            </div>
        )
    }

    const msgBoxClose = () => {
        setMsgBoxOpen(false);
    }

    const handleMsgInputChange = (event) => {
        //console.log(event.target.name, event.target.value);
        setMsgToDiver(event.target.value);
    };

    const messageSubmit = () => {  
        //this.setState({openMessageBox: false});
        setMsgBoxOpen(false);  
        HttpRequest.sendDriverMessage(accessToken, selectedDriver, props.companyID, undefined,  msgToDriver);     
    }   
  
    return(  
        <div className='fullWrapper container_message-history'>           
            <Box className='appBarDark' display = 'flex' flexWrap= 'nowrap' flexDirection='column' m={0}> 
                <Box
                    display = 'flex' 
                    bgcolor = 'transparent'
                    border = {0}
                    justifyContent = 'space-between' 
                    alignItems = 'center'
                    m={1}
                >                          
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker              
                            renderInput={(props) => <TextField variant='outlined' autoFocus={true} {...props} />}
                            label = {<Typography  style = {{fontSize: '16px', fontWeight: 'bold'}}>Start</Typography>}
                            value = {startDate}
                            ampm={false}
                            onChange = {(newDate) => {handleDateChange(newDate)}}/>
                    </LocalizationProvider>       
                                   
                    <Button
                            variant = 'contained'
                            size='large'
                            className='getMessagesBtn'
                            onClick = {() => handleSearch(startDate, searchText)}>
                            <span>Get Messages</span><TextsmsOutlinedIcon/>
                    </Button>               
                </Box>    
            </Box>

           <div className = {resultsVisible ? '' : classes.invisible}>    
                <div className='px-lg'>
                <DataGrid
                    dataSource={searchResults}
                    showBorders={true}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    keyExpr="id"
                    rowAlternationEnabled={true}        
                >                    
                    {/* <FilterRow visible={true} /> */}
                    <Paging 
                       defaultPageSize={15}       
                     />
                    <SearchPanel visible={true}
                      width={240}
                      placeholder="Search..." />  
                    <Pager
                       visible={true}
                       showInfo={true}
                       infoText="Page #{0}. Total: {1} ({2} items)"  
                       displayMode="full"
                     />
                    <Column dataField="direction" caption="Direction" width={100}/>
                    <Column dataField="messageText" caption="Message" minWidth={300} width={'100%'}/>
                    <Column dataField="datestampUTC" caption="Time" cellRender={renderTimestamp} width={120}/>  
                    <Column dataField="jobID" caption="Job ID" cellRender={renderJobID} width={100}/>  
                    <Column dataField="drNum" caption="Driver" cellRender={renderDrNum} width={100}/>
                    <Column dataField="companyID" caption="company ID" width={100}/>
                    <Column dataField="class" caption="Class" width={100}/>  
    
                </DataGrid>
                </div>
            </div> 

            <Dialog open={msgBoxOpen} onClose={msgBoxClose}>
                    <DialogTitle>Send Message</DialogTitle>
                    <DialogContent>
                        <TextField
                           autoFocus
                           margin="dense"
                           name = "message"
                           label="Message"
                           type="text"
                           fullWidth
                           variant="standard"
                           onChange={handleMsgInputChange}  
                       />
                    </DialogContent>
                    <DialogActions>
                       <Button onClick={msgBoxClose}>Cancel</Button>
                       <Button onClick={messageSubmit}>Submit</Button>
                    </DialogActions>
            </Dialog>
            <footer  style = {{marginTop: '20px'}}>
                <Copyright/>
            </footer>
        </div>
    )
}
  