import axios from "axios";

//const base_url = "https://wdin5apiprod.azurewebsites.net/";
//const wd5_base_url = "https://wdweb5apiprod.azurewebsites.net/";

const base_url = "https://inxapi-staging.azurewebsites.net/";
//const base_url = "https://inxapi.azurewebsites.net/";
const wd5_base_url = "https://webxapi.azurewebsites.net/";

class HttpRequest {

  getAPIVersion = () => {
    return axios.get(base_url);
  };

  in_login = (body) => {
    return axios.post(base_url + "inlogin", body);
  };

  generateVerifyCode = (body) => {
    return axios.patch(base_url + "generateVerifyCode", body);
  };

  getStatusDD = (accessToken) => {
    return axios.get(base_url + "statusDD/" + accessToken);
  };

  getLocationDD = (accessToken) => {
    return axios.get(base_url + "locationDD/" + accessToken);
  };

  getTripRating = (accessToken, id) => {
    return axios.get(base_url + "tripRating/" + accessToken + "/" + id);
  };

  getTripDrivers = (accessToken, id) => {
    return axios.get(base_url + "tripDrivers/" + accessToken + "/" + id);
  };

  getTripFunctions = (accessToken, id) => {
    return axios.get(base_url + "tripFunctions/" + accessToken + "/" + id);
  };

  getTripHistory = (accessToken, id) => {
    return axios.get(base_url + "tripHistory/" + accessToken + "/" + id);
  };

  getTrip = (accessToken, id) => {
    return axios.get(base_url + "tripDetail/" + accessToken + "/" + id);
  };

  getTripAccountList = (accessToken, id) => {
    return axios.get(base_url + "tripAccountList/" + accessToken + "/" + id);
  };

  getAccountList = (accessToken) => {
    return axios.get(base_url + "accountList/" + accessToken);
  };

  getAccountWebToken = (accessToken, accountNumber) => {
    return axios.get(
      base_url + "accountToken/" + accessToken + "/" + accountNumber
    );
  };

  getSearchJobID = (accessToken, searchedJobID) => {
    return axios.get(
      base_url + "jobIDSearch/" + accessToken + "/" + searchedJobID
    );
  };

  getSearchJobID = (accessToken, searchedJobID) => {
    return axios.get(
      base_url + "jobIDSearch/" + accessToken + "/" + searchedJobID
    );
  };

  getmapPointsJobID = (accessToken, jobID, driverNumber) => {
    return axios.get(
      base_url +
        "mapPointsJobID/" +
        accessToken +
        "/" +
        driverNumber +
        "/" +
        jobID
    );
  };

  getMapPointsAll = (accessToken, companyID) => {
    return axios.get(
      base_url + "mapPointsAll/" + accessToken + "/" + companyID
    );
  };

  getMapPointsByBoard = (accessToken, companyID, drvGroupID, boardID) => {
    return axios.get(
      base_url +
        "mapPointsAll/" +
        accessToken +
        "/" +
        companyID +
        "/" +
        drvGroupID +
        "/" +
        boardID
    );
  };

  getMapPointsByDrvGroup = (accessToken, companyID, drvGroupID) => {
    return axios.get(
      base_url +
        "mapPointsAll/" +
        accessToken +
        "/" +
        companyID +
        "/" +
        drvGroupID
    );
  };

  getDriverGroup = (accessToken) => {
    return axios.get(base_url + "driverGroups/" + accessToken);
  };

  getMessagesFromDriver = (accessToken, companyID, driverNum) => {
    return axios.get(
      base_url +
        "messagesDriver/" +
        accessToken +
        "/" +
        companyID +
        "/" +
        driverNum
    );
  };

  getServiceMessages = (accessToken) => {
    return axios.get(base_url + "serviceMessages/" + accessToken);
  };

  getDriverStats = (accessToken, companyID) => {
    return axios.get(base_url + "driverStats/" + accessToken + "/" + companyID);
  };

  getTripStats = (accessToken, companyID) => {
    return axios.get(base_url + "tripStats/" + accessToken + "/" + companyID);
  };

  getTripsDispatch = (accessToken, boardID) => {
    return axios.get(base_url + "tripsDispatch/" + accessToken + "/" + boardID);
  };

  getTripListFunctions = (accessToken, id) => {
    return axios.get(base_url + "tripListFunctions/" + accessToken);
  };

  getDispatchBoards = (accessToken) => {
    return axios.get(base_url + "dispatchBoards/" + accessToken);
  };

  getRateChoice = (accessToken, id) => {
    return axios.get(base_url + "rateChoice/" + accessToken + "/" + id);
  };

  getTripServiceNotes = (accessToken, id) => {
    return axios.get(base_url + "tripServiceNotes/" + accessToken + "/" + id);
  };

  getTripPackageDescriptions = (accessToken, id) => {
    return axios.get(
      base_url + "tripPackageDescriptions/" + accessToken + "/" + id
    );
  };

  getTripDocuments = (accessToken, id) => {
    return axios.get(base_url + "tripDocuments/" + accessToken + "/" + id);
  };

  getUserSettings = (accessToken, settingsKey) => {
    return axios.get(
      base_url + "userSettings/" + accessToken + "/" + settingsKey
    );
  };

  getUserCompanyList = (accessToken) => {
    return axios.get(base_url + "companyList/" + accessToken);
  };

  getReferenceFields = (accessToken, jobID) => {
    return axios.get(base_url + "referenceFields/" + accessToken + "/" + jobID);
  };

  getReferenceDropDown = (accessToken, refID) => {
    return axios.get(
      base_url + "referenceDropDown/" + accessToken + "/" + refID
    );
  };

  getFedexOrderDetail = (trackingNumber) => {
    return axios.get(base_url + "fedexOrderDetail/" + trackingNumber);
  };

  tripSearch = (accessToken, searchParams) => {
    return axios.post(base_url + "tripSearch/" + accessToken, searchParams);
  };

  jobIDListSearch= (accessToken, jobID_List) => {
    var content = {     
      jobIDList: jobID_List,
    };
    return axios.post(base_url + "jobIDListSearch/" + accessToken, content);
  };

  doTripListFunction = (accessToken, jobID_List, params, procedureName) => {
    var content = {
      paramList: params,
      spName: procedureName,
      jobIDList: jobID_List,
    };
    return axios.post(base_url + "doTripListFunction/" + accessToken, content);
  };

  doTripFunction = (accessToken, jobID, params, procedureName) => {
    var content = {
      paramList: params,
      spName: procedureName,
      jobIDList: "",
    };
    return axios.post(
      base_url + "doTripFunction/" + accessToken + "/" + jobID,
      content
    );
  };

  sendDriverMessage = (accessToken, drNum, companyID, jobID, message) => {
    var content = {
      messageText: message,
      jobID: jobID,
      drNum: drNum,
      companyID: companyID,
    };
    return axios.post(base_url + "driverMessage/" + accessToken, content);
  };

  postUserSettings = (accessToken, settingsKey, filterQuery) => {
    var content = {
      settingsJson: filterQuery,
    };
    return axios.post(
      base_url + "userSettings/" + accessToken + "/" + settingsKey,
      content
    );
    //return axios.post(base_url + 'userSettings/' + accessToken, content);
  };

  doUploadPicture = (accessToken, jobID, base64String, comment, blobURL) => {
    var content = {
      URL: blobURL,
      comment: comment,
    };
    return axios.post(
      base_url + "picture/" + accessToken + "/" + jobID,
      content
    );
  };

  doUploadImage = (accessToken, base64String, blobFileName) => {
    var content = {
      base64: base64String,
      fileName: blobFileName,
    };
    return axios.post(base_url + "image/" + accessToken, content);
  };

  /**
   * doUploadPDF
   * @param accessToken
   * @param params
   */
  doUploadFile = (accessToken, base64String, blobFileName) => {
    var content = {
      base64: base64String,
      fileName: blobFileName,
    };
    return axios.post(base_url + "file/" + accessToken, content);
  };

  userUpdate = (accessToken, account, companyID, drNum) => {
    var content = {
      account: account,
      companyID: companyID,
      drNum: drNum,
    };
    return axios.post(base_url + "user/" + accessToken, content);
  };

  updateJobService = (accessToken, jobID, service) => {
    var content = {
      jobID: jobID,
      ser_area: service.ser_area,
      ser_time: service.ser_time,
    };
    return axios.patch(base_url + "orderRateFields/" + accessToken, content);
  };

  // updateJobRateFields = (accessToken,order, jobId) => {
  //      let content = {
  //           ...order,
  //           jobID: jobId
  //      }
  //      console.log('>>>>>>>>>>>>updateJobRateFields--', JSON.stringify(content));
  //      return axios.patch(base_url + 'orderRateFields/' + accessToken, content)
  // }

  updateJobRateFields = (accessToken, order) => {
    console.log(">>>>>>>>>>>>updateJobRateFields--", JSON.stringify(order));
    return axios.patch(base_url + "orderRateFields/" + accessToken, order);
  };

  updateJobFields = (accessToken, order) => {
    return axios.patch(base_url + "orderFields/" + accessToken, order);
  };

  clearServiceQueue = (accessToken, serviceQueID) => {
    return axios.patch(
      base_url + "serviceQueue/" + accessToken + "/" + serviceQueID
    );
  };

  claimServiceQueue = (accessToken, serviceQueID) => {
    return axios.patch(
      base_url + "serviceQueueClaim/" + accessToken + "/" + serviceQueID
    );
  };

  postReferenceFields = (accessToken, jobID, reference) => {
    var content = {
      jobID: jobID,
      reference: reference,
    };
    return axios.patch(base_url + "referenceFields/" + accessToken, content);
  };

  getMessagesAll = (
    accesstoken,
    companyID,
    startTime,
    maxRecord,
    msgSearch
  ) => {
    var content = {
      since: startTime,
      take: maxRecord,
      text: msgSearch,
    };
    return axios.post(
      base_url + "messagesAll/" + accesstoken + "/" + companyID,
      content
    );
  };

  updateDriverShareCount = (accesstoken, item) => {
    return axios.post(base_url + "driverShareCount/" + accesstoken, item);
  };

  updateZrateExempt = (accesstoken, jobID, item) => {
    return axios.post(
      base_url + "zrateExempt/" + accesstoken + "/" + jobID,
      item
    );
  };

  setHiddenImage = (accesstoken, item) => {
    return axios.post(base_url + "imageHidden/" + accesstoken, item);
  };



  /* breadcrumbPoints post*/
  getBreadcrumbPointsWithRange = (accessToken, drNum, startTime, endTime) => {
    var content = {
      startTimeUTC: startTime,
      endTimeUTC: endTime     
    };
    return axios.post(
      base_url + "breadcrumbPoints/" + accessToken + "/" + drNum,
      content
    );
  };

  /* breadcrumbPoints post*/
  getBreadcrumbPoints = (accessToken, drNum) => {    
    return axios.post(
      base_url + "breadcrumbPoints/" + accessToken + "/" + drNum, {});
  };


  //Web5 API calls
  /* updateAddressContact  -- tested*/
  updateAddressContact = (accountNumber, addressID, contactID) => {
    var accessToken = localStorage.getItem("access_token");
    var content = {
      addressID: addressID,
      contactID: contactID,
    };
    return axios.post(
      base_url + "addressContact/" + accountNumber + "/" + accessToken,
      content
    );
    //return axios.post(base_url + "addressContact/" + accountNumber, content);
  };

  /* addContact  ---------------*/
  addContact = (accountNumber, contact) => {
    var accessToken = localStorage.getItem("access_token");
    var content = {
      contact: contact,
      accountNumber: accountNumber,
    };
    return axios.post(base_url + "contact" + "/" + accessToken, content);
    //return axios.post(base_url + "contact", content);
  };

  /* get Order Track  - tested */
  getOrderTrack = (orderID) => {
    var accessToken = localStorage.getItem("access_token");
    return axios.get(base_url + "track/" + orderID + "/" + accessToken);
    //  return axios.get(base_url + "track/" + orderID); //wd5_base_url
  };

  /**
   * Post Address - tested
   * @param accountNumber
   **/
  postAddress = (accountNumber, address) => {
    var accessToken = localStorage.getItem("access_token");
    var content = {
      address: address,
      accountNumber: accountNumber,
    };
    return axios.post(base_url + "address" + "/" + accessToken, content);
    // return axios.post(base_url + "address", content); //wd5_base_url
  };

  /**
   * post address with addressID and google return json string  -- tested
   * @param addressID
   * @param addrSearchResult
   */

  postAddressEXT = (addressID, addrSearchResult) => {
    var accessToken = localStorage.getItem("access_token");
    var content = {
      addrSearchResult: addrSearchResult,
      addressID: addressID,
    };
    return axios.post(base_url + "addressEXT" + "/" + accessToken, content);
    //return axios.post(base_url + "addressEXT", content);
  };

  /**
   * get Job GPS - tested
   * @param orderID
   **/
  getJobGPS = (orderID) => {
    var accessToken = localStorage.getItem("access_token");
    return axios.get(base_url + "jobGPS/" + orderID + "/" + accessToken);
  };

  /**
   * get Caller list - tested
   * @param account
   **/
  getUserEmails = (account) => {
    var accessToken = localStorage.getItem("access_token");
    return axios.get(base_url + "userEmails/" + account + "/" + accessToken);
    //return axios.get(wd5_base_url + "userEmails/" + account);
  };

   /**
   * add caller  
   **/
    postUserEmails = (accountNumber, name) => {
      var accessToken = localStorage.getItem("access_token");
      var content = {
        accountNumber: accountNumber,
        caller: {
          username:name,
          emailAddress: ""
        }      
      };
      return axios.post(base_url + "userEmails/" + accessToken, content);
      //return axios.post(base_url +  "userEmails", content);
    };


  /**
   * remove Caller  
   **/
  removeCaller = (accountNumber, userName) => {
    var accessToken = localStorage.getItem("access_token");
    var content = {
      username: userName      
    };
    return axios.post(base_url + "caller/" + accountNumber + "/" + accessToken, content);
    //return axios.post(base_url +  "caller/" + accountNumber, content);
  };

  /**
   * top addresses
   * @param accountNumber
   * @param search
   * @returns {*}
   */
  getTopAddress = (accountNumber, search) => {
    var accessToken = localStorage.getItem("access_token");
    return axios.post(
      base_url + "topAddress/" + accountNumber + "/" + accessToken,
      search
    );
    //return axios.post(base_url + "topAddress/" + accountNumber, search);
  };

  getTopAddressByType = (accountNumber, search, type) => {
    var accessToken = localStorage.getItem("access_token");
    return axios.post(
      base_url + "topAddress/" + accountNumber + "/" + type + "/" + accessToken,
      search
    );
    // return axios.post(
    //   base_url + "topAddress/" + accountNumber + "/" + type,
    //   search
    // );
  };

  /*
   * expand top addresses
   * @param accountNumber
   * @param search
   * @returns {*}
   */
  getExpandAddress = (accountNumber, search, skip) => {
    var accessToken = localStorage.getItem("access_token");
    return axios.post(
      base_url +
        "expandAddress/" +
        accountNumber +
        "/" +
        skip +
        "/" +
        accessToken,
      search
    );
    // return axios.post(
    //   base_url + "expandAddress/" + accountNumber + "/" + skip,
    //   search
    // );
  };
}

const httpRequest = new HttpRequest();

export default httpRequest;
